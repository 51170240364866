<template>
  <base-card class="update-company-info">
    <v-card-text>
      <v-form
        ref="form"
        v-model="validForm"
        lazy-validation
      >
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="companyName"
                  :label="$t('partner.companyName')"
                  :rules="requiredRules"
                  hide-details
                />
              </v-col>

              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="taxId"
                  :label="$t('partner.taxId')"
                  :rules="requiredRules"
                  hide-details
                />
              </v-col>

              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="phoneNumber"
                  :label="$t('common.phoneNumber')"
                  :rules="requiredRules"
                  hide-details
                />
              </v-col>

              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="partnerCode"
                  :label="$t('partner.partnerCode')"
                  hide-details
                  disabled
                />
              </v-col>

              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="subject"
                  :label="$t('partner.mainWebsite')"
                  disabled
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="6" class="pb-0">
            <v-textarea
              v-model="altnames"
              :label="$t('partner.altNames')"
              row-height="20"
              auto-grow
              hide-details
            />
            <div class="mt-1 orange--text text--darken-4">
              1. {{ $t("partner.inputValidationUrl") }}<br>
              2.
              {{
                $t(
                  "partner.inputSeparateDifferenceAltNamesWithCommanAndNewLine"
                )
              }}<br>
              ex:<br>
              gogo.partner.servercentralen.se,<br>
              boundless.partner.servercentralen.se,<br>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="!!partnerId" cols="12" sm="3" class="pb-0">
            <div class="logo-container">
              <img
                :src="logoUrl"
                :max-width="300"
              />
            </div>

            <file-uploader
              ref="fileUploader"
              accepted-file-types=".png, .gif, .jpeg, .jpg"
              :url="uploadLogoUrl"
              :upload-multiple="false"
              :timeout="1000"
              :is-disable="isLoading"
              :dict-default-message="$t('partner.clickToUploadImage')"
              @uploadComplete="uploadSuccess"
              @uploadStart="uploadStart"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions class="justify-center pb-4">
      <v-btn
        class="primary px-8"
        :disabled="!validForm"
        @click.native="updatePartnerInfo"
      >
        {{ $t("common.update") }}
      </v-btn>
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  import FileUploader from '../components/FileUploader'

  export default {
    metaInfo: {
      title: 'Update Company Info',
    },

    components: {
      FileUploader,
    },

    data () {
      return {
        companyName: '',
        taxId: '',
        phoneNumber: '',
        partnerCode: '',
        subject: '',
        altnames: '',
        partnerId: null,
        logo: '',
        isLoading: false,
        errorMessage: '',
        token: '',
        validForm: true,
        requiredRules: [
          value => !!value || this.$t('error.requiredField'),
        ],
      }
    },

    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
      }),

      uploadLogoUrl () {
        return `${util.apiURL}partner/logo?partnerId=${this.partnerId}`
      },

      logoUrl () {
        if (!this.partnerId || !this.logo) {
          return '/static/img/logo.png'
        }

        return `${util.publicURL}/_partner_logos/${this.logo}`
      },
    },

    watch: {
      currentUser (value) {
        if (value) {
          this.initForm()
        }
      },
    },

    mounted () {
      this.initForm()
    },

    methods: {
      ...mapActions({
        updatePartner: 'updatePartner',
        fetchCurrentUser: 'me',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      initForm() {
        this.companyName = this.currentUser.partner?.companyName
        this.taxId = this.currentUser.partner?.taxId
        this.phoneNumber = this.currentUser.partner?.phoneNumber
        this.partnerCode = this.currentUser.partner?.code
        this.subject = this.currentUser.partner?.partnerSSL?.subject
        this.altnames =
          this.currentUser.partner?.partnerSSL?.altnames.join(',\n') || ''
        this.partnerId = this.currentUser.partner?.id
        this.logo = this.currentUser.partner?.logo
      },

      async updatePartnerInfo() {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        this.altnames = this.altnames.replace(/ /g, '').replace(/\n/g, '')
        const partnerAltnames =
          (this.altnames && this.altnames.split(',').filter((item) => !!item)) ||
          []

        this.setLoadingText(this.$t('partner.updatingPartnerInfo'))
        this.setLoading(true)
        try {
          await this.updatePartner({
            id: this.partnerId,
            companyName: this.companyName,
            taxId: this.taxId,
            phoneNumber: this.phoneNumber,
            code: this.partnerCode,
            isActive: this.currentUser.partner?.isActive || false,
            subject: this.subject,
            altnames: partnerAltnames.length > 0 ? partnerAltnames : null,
          })

          await this.fetchCurrentUser()

          showSuccessDialog(this.$t('partner.updateInformationSuccessfully'))
        } catch (error) {
          const errorMessage = util.getErrorResponse(error)
          showErrorDialog('Error', errorMessage)
        }
        this.setLoading(false)
      },

      uploadStart() {
        this.isLoading = true
      },

      uploadSuccess(response) {
        this.logo = response.filename
        this.isLoading = false

        this.fetchCurrentUser()
      },
    },
  }
</script>

<style lang="scss">
  .update-company-info {
    .logo-container {
      border: 1px solid #b2b2b2;
      text-align: center;
    }
    .logo {
      max-width: 300px;
    }

    .uploader {
      .dropzone {
        min-height: unset;
        height: 30px;
        padding: 0;
        border: 0;

        .dz-message {
          display: block !important;
          margin: 5px 0;

          .dz-button {
            font-size: 14px;
            color: #e65100;
          }
        }

        .dz-preview {
          display: none;
        }
      }
    }
  }
</style>
